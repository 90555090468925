import axios from 'axios'

const initAxios = axios.create()

const axiosClient = (config) => {
  const {
    VUE_APP_LOGGED_TOKEN,
    VUE_APP_API_URL
  } = process.env

  const configHeaders = config.headers || {}
  const loggedToken = localStorage.getItem(VUE_APP_LOGGED_TOKEN)
  const headers = {
    Authorization: loggedToken ? `Bearer ${loggedToken}` : '',
    ...configHeaders
  }

  const axiosConfig = Object.assign({
    baseURL: `${VUE_APP_API_URL}`,
    withCredentials: false,
    validateStatus (status) {
      return status >= 200 && status < 500
    }
  },
  config,
  { headers })

  return initAxios(axiosConfig)
    .then((result) => {
      const { status, data } = result
      if (result.status >= 400) {
        const returnData = { ...data, status }
        return {
          type: 422,
          payload: returnData
        }
      }
      return { type: 200, payload: data }
    })
    .catch(() => ({
      type: 500,
      payload: { message: 'Unable to connect to server.' }
    }))
}

export default axiosClient
