<template>
  <div class="position-relative">
    <preloader
      v-if="showLoading"
      color="primary"
      :grow="false"
    />
    <CButton v-if="showCreateNew" @click="onInsertNew" color="success" class="mr-1">Create & New</CButton>
    <CButton v-if="showCreateClose" @click="onInsertClose" color="success" class="mr-1">Create & Close</CButton>
    <CButton @click="onCancel" color="danger">Cancel</CButton>
  </div>
</template>

<script>
export default {
  name: 'FormCreateButton',
  props: {
    showCreateNew: {
      type: Boolean,
      default: true
    },
    showCreateClose: {
      type: Boolean,
      default: true
    },
    showLoading: Boolean
  },
  methods: {
    onInsertNew () {
      this.$emit('insertNew')
    },
    onInsertClose () {
      this.$emit('insertClose')
    },
    onCancel () {
      this.$emit('cancel')
    }
  }
}
</script>
