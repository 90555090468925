export default {
  session: {
    loggedUser: null,
    permissions: [],
    sidebarMenu: []
  },
  notifications: [],
  sidebarShow: 'responsive',
  sidebarMinimize: false
}
