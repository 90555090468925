import axios from '@/utils/axios'
import generateKey from '@/utils/generateKey'

export default {
  addNotification ({ commit }, payload) {
    const notification = {
      key: generateKey(),
      ...payload
    }
    commit('ADD_NOTIFICATION', notification)
  },
  async validateToken ({ commit }) {
    const { VUE_APP_LOGGED_TOKEN } = process.env
    if (!localStorage.getItem(VUE_APP_LOGGED_TOKEN)) {
      return
    }
    const { type, payload } = await axios({ url: 'token/validate', method: 'GET' })
    if (type === 200) {
      commit('ADD_LOGGED_USER_DATA', payload.user)
    }
  },
  async logout ({ commit }) {
    const { VUE_APP_LOGGED_TOKEN } = process.env
    await axios({ url: 'logout', method: 'POST' })
    localStorage.removeItem(VUE_APP_LOGGED_TOKEN)
    commit('ADD_LOGGED_USER_DATA', null)
  }
}
