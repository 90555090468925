<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState({
      notifications: state => state.notifications
    })
  },
  watch: {
    notifications () {
      if (this.notifications.length > 0) {
        const [n] = this.notifications
        this.$toastr.Add({
          name: n.key,
          msg: n.message,
          clickClose: true,
          timeout: 3500,
          position: 'toast-top-right',
          type: n.type
        })
        this.$store.commit('REMOVE_NOTIFICATION')
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  // @import '@vueform/multiselect/themes/default.css';
  @import 'assets/scss/style';
</style>
