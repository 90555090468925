import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Page404 from '@/views/Page404'

// Containers
const TheContainer = () => import(/* webpackChunkName: "container" */ '@/containers/TheContainer')

// Auth Views
const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login')
const Register = () => import(/* webpackChunkName: "register" */ '@/views/Register')

// Portal Views
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Lists')
const Kennel = () => import(/* webpackChunkName: "kennel" */ '@/views/Kennel/Lists')
const Dog = () => import(/* webpackChunkName: "dog" */ '@/views/Dog/Lists')
const Breed = () => import(/* webpackChunkName: "breed" */ '@/views/Breed/Lists')
const Handler = () => import(/* webpackChunkName: "handler" */ '@/views/Handler/Lists')
const Event = () => import(/* webpackChunkName: "event" */ '@/views/Event/Lists')
const Club = () => import(/* webpackChunkName: "club" */ '@/views/Club/Lists')
const EventLocation = () => import(/* webpackChunkName: "event-location" */ '@/views/EventLocation/Lists')
const Secretary = () => import(/* webpackChunkName: "secretary" */ '@/views/Secretary/Lists')

// Admin Views
const Stake = () => import(/* webpackChunkName: "stake" */ '@/views/Stake/Lists')
const User = () => import(/* webpackChunkName: "user" */ '@/views/User/Lists')
const Profile = () => import(/* webpackChunkName: "profile" */ '@/views/Profile/Lists')

Vue.use(VueRouter)

const guest = true
const byPass = true
const requiresAuth = true

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { guest }
  },
  {
    path: '/verify-url/:token',
    name: 'VerifyUrl',
    beforeEnter: async (to, from, next) => {
      const { token } = to.params
      const { VUE_APP_LOGGED_TOKEN } = process.env
      localStorage.setItem(VUE_APP_LOGGED_TOKEN, token)
      await store.dispatch('validateToken')
      if (store.state.session.loggedUser) {
        store.dispatch('addNotification', { type: 'success', message: 'Email verified successfully.' })
        next({ name: 'Dashboard' })
      } else {
        localStorage.removeItem(VUE_APP_LOGGED_TOKEN)
        store.dispatch('addNotification', { type: 'error', message: 'Invalid token data.' })
        next('login')
      }
    }
  },
  {
    path: '/user',
    name: 'Portal',
    redirect: '/user/dashboard',
    component: TheContainer,
    meta: { requiresAuth },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'kennels',
        name: 'Kennels',
        component: Kennel
      },
      {
        path: 'dogs',
        name: 'Dogs',
        component: Dog
      },
      {
        path: 'breeds',
        name: 'Breeds',
        component: Breed
      },
      {
        path: 'stakes',
        name: 'Stakes',
        component: Stake
      },
      {
        path: 'handlers',
        name: 'Handlers',
        component: Handler
      },
      {
        path: 'events',
        name: 'Events',
        component: Event
      },
      {
        path: 'clubs',
        name: 'Clubs',
        component: Club
      },
      {
        path: 'event_locations',
        name: 'Event Locations',
        component: EventLocation
      },
      {
        path: 'secretaries',
        name: 'Secretaries',
        component: Secretary
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'users',
        name: 'Users',
        component: User
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'Redirect Wildcard',
    component: Page404,
    meta: { byPass }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.byPass)) {
    next()
    return
  }
  let loggedUser = store.state.session.loggedUser
  if (!loggedUser) {
    await store.dispatch('validateToken')
    loggedUser = store.state.session.loggedUser
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedUser) {
      next({ name: 'Login' })
      return
    }
  }
  if (to.matched.some(record => record.meta.guest)) {
    if (loggedUser) {
      next({ name: 'Dashboard' })
      return
    }
  }
  next()
})

export default router
