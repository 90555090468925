<template>
  <div class="position-relative">
    <preloader
      v-if="showLoading"
      color="primary"
      :grow="false"
    />
    <CButton @click="onUpdate" color="success" class="mr-1">Update</CButton>
    <CButton @click="onUpdateAndClose" color="success" class="mr-1">Update & Close</CButton>
    <CButton @click="onCancel" color="danger">Cancel</CButton>
  </div>
</template>

<script>
export default {
  name: 'FormUpdateButton',
  props: {
    showUpdate: {
      type: Boolean,
      default: true
    },
    showUpdateAndClose: {
      type: Boolean,
      default: true
    },
    showLoading: Boolean
  },
  methods: {
    onUpdate () {
      this.$emit('update')
    },
    onUpdateAndClose () {
      this.$emit('updateAndClose')
    },
    onCancel () {
      this.$emit('cancel')
    }
  }
}
</script>
