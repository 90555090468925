<template>
  <div class="page-filter--input position-relative">
    <CInput
      v-model="value"
      :name="fieldKey"
      :label="label"
      :placeholder="label"
      @change="onChange"
    />
    <CButton
      v-if="value.length > 0"
      shape="pill"
      color="danger"
      variant="outline"
      size="sm"
      style="position: absolute; top: 32px; right: 5px;"
      tabindex="-1"
      @click="onClearSearch"
    >
      <CIcon name="cil-x" size="sm" />
    </CButton>
  </div>
</template>
<script>
export default {
  name: 'PageFilterInput',
  props: {
    fieldKey: String,
    default: String,
    label: String,
    onUpdate: Function
  },
  data () {
    return {
      value: this.default || ''
    }
  },
  methods: {
    onChange () {
      this.onUpdate(this.fieldKey, this.value)
    },
    onClearSearch () {
      this.value = ''
      this.onChange()
    }
  }
}
</script>
