export default {
  ADD_NOTIFICATION (state, payload) {
    state.notifications.push(payload)
  },
  REMOVE_NOTIFICATION (state) {
    const notifications = state.notifications
    notifications.shift()
    state.notifications = notifications
  },
  ADD_LOGGED_USER_DATA (state, payload) {
    state.session = { ...state.session, loggedUser: payload }
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}
