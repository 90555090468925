<template>
  <div :class="containerClass" class="d-md-flex justify-content-between">
    <div class="pagination-text">
      <p>Displaying {{ pageData.from }} to {{ pageData.to }} of {{ pageData.total }} total {{ listEntity }}</p>
    </div>
    <div class="pagination-actions d-md-flex">
      <CSelect
        :label="perPageSelectData.label"
        :value="pageData.perPage"
        :options="perPageSelectData.options"
        :horizontal="{label: 'col-sm-4 col-md-8', input: 'col-sm-4 w-25'}"
        size="sm"
        addWrapperClasses="pr-2"
        @update:value="onPaginationChange"
      />
      <CPagination
        :activePage="pageData.currentPage"
        :pages="pageData.totalPage"
        size="sm"
        align="center"
        @update:activePage="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import lowerCase from 'lodash/toLower'
import upperCase from 'lodash/upperCase'
export default {
  name: 'ListPagination',
  props: {
    pageData: Object,
    perPageSelectData: Object,
    entity: String,
    containerClass: {
      type: String,
      defaultValue: ''
    }
  },
  data () {
    return {
      listEntity: lowerCase(upperCase(this.entity))
    }
  },
  methods: {
    onPageChange (currentPage) {
      this.$emit('pageChange', { currentPage })
    },
    onPaginationChange (perPage) {
      this.$emit('pageChange', { perPage })
    }
  }
}
</script>
