<template>
  <div class="filter-container">
    <div class="mb-2 text-right">
      <CButton
        color="primary"
        size="sm"
        variant="outline"
        @click="onShowFilter"
      >
        Show Filter
      </CButton>
    </div>
    <CModal
      :show="showFilter"
      :no-close-on-backdrop="true"
      size="lg"
      color="info"
    >
      <CForm v-if="showForm" autocomplete="off">
        <CRow :key="index" v-for="(filter, index) in filterData">
          <CCol sm="12">
            <PageFilterSelect v-if="filter.type === 'select'" v-bind="filter" :onUpdate="onUpdateFilter" />
            <PageFilterInput v-if="filter.type === 'input'" v-bind="filter" :onUpdate="onUpdateFilter" />
            <!-- <PageFilterRadio v-if="filter.type === 'radio'" v-bind="filter" onUpdate="onUpdateFilter" />
            <PageFilterCheckbox v-if="filter.type === 'checkbox'" v-bind="filter" onUpdate="onUpdateFilter" /> -->
          </CCol>
        </CRow>
      </CForm>
      <template #header>
        <h3 class="modal-title">Filter</h3>
        <CButtonClose @click="onClose" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="onFilter" color="success" class="mr-1">Apply Filter</CButton>
        <CButton v-if="isFiltered" @click="onClear" color="primary" class="mr-1">Clear</CButton>
        <CButton @click="onClose" color="danger">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: 'PageFilter',
  props: {
    filterData: {
      type: Array,
      defaultValue: []
    },
    onFilterUpdate: Function
  },
  data () {
    return {
      showFilter: !1,
      showForm: !0,
      isFiltered: !1,
      filters: {}
    }
  },
  methods: {
    onShowFilter () {
      this.showFilter = !0
      this.showForm = !0
    },
    onUpdateFilter (key, value) {
      this.filters[key] = value
    },
    onFilter () {
      this.isFiltered = !0
      this.onFilterUpdate(this.filters)
      this.showFilter = !1
    },
    onClear () {
      this.showForm = !1
      this.initFilterData()
      // this.showForm = !0
      this.onFilter()
      this.isFiltered = !1
    },
    onClose () {
      this.showFilter = !1
    },
    initFilterData () {
      this.filters = this.filterData.reduce((retData, e) => {
        if (e.type === 'multiselect') {
          retData[e.fieldKey] = e.default.map((d) => d.value)
        } else {
          retData[e.fieldKey] = e.default
        }
        return retData
      }, {})
    },
    mounted () {
      this.initFilterData()
    }
  }
}
</script>
