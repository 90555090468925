import Vue from 'vue'
import CoreuiVue from '@coreui/vue'
import VueToastr from 'vue-toastr'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { ListSelect, ModelListSelect } from 'vue-search-select'
import { Plugin } from 'vue-fragment'
import Multiselect from 'vue-multiselect'

import App from '@/App'

import titleMixin from '@/mixins/titleMixin'
import { iconsSet as icons } from '@/assets/icons/icons.js'
import router from '@/router'
import store from '@/store'

// Register Plugins
Vue.use(CoreuiVue)
Vue.use(VueToastr)
Vue.use(Plugin)

// Register Mixins
Vue.mixin(titleMixin)

// Register Components
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('vue-list-select', ListSelect)
Vue.component('model-list-select', ModelListSelect)
Vue.component('Multiselect', Multiselect)

// Register Common Components
const files = require.context('@/common/components/', true, /\.vue$/i)
files.keys().map((key) => {
  Vue.component(key.replace(/\//g, '').split('.')[1], files(key).default)
})

const directives = require.context('@/common/directives/', true, /\.js$/)
directives.keys().map((key) => {
  Vue.directive(key.replace(/\//g, '').split('.')[1], directives(key).default)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')
