<template>
  <div
    class="d-flex justify-content-center position-absolute w-100"
    style="background: rgba(255, 255, 255, 0.5);padding: 2px 0;"
    :style="containerStyle"
    :class="containerClass"
  >
    <CSpinner
      :color="color"
      :grow="grow"
    />
    <span v-if="text" class="ml-2">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    containerClass: [String, Array],
    containerStyle: String,
    grow: Boolean,
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
