<template>
  <div class="page-filter--select">
    <CSelect
      :label="label"
      :value="value"
      :options="options"
      @update:value="onChange"
    >
    </CSelect>
  </div>
</template>
<script>
export default {
  name: 'PageFilterSelect',
  props: {
    fieldKey: String,
    options: Array,
    default: String,
    label: String,
    onUpdate: Function
  },
  data () {
    return {
      value: (this.options.find((e) => e.value === this.default) || {}).value
    }
  },
  methods: {
    onChange (value) {
      this.value = value
      this.onUpdate(this.fieldKey, this.value)
    }
  }
}
</script>
